.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #2a2a2a;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.popup-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.popup-content input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 4px;
    background: #333;
    color: #fff;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
}

.popup-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #3498db;
    color: #fff;
    transition: background-color 0.3s ease;
}

.popup-buttons button:hover {
    background: #2980b9;
}

.popup-buttons button:first-child {
    background: #555;
}

.popup-buttons button:first-child:hover {
    background: #444;
}

.popup-buttons button:disabled {
    background: #999;
    cursor: not-allowed;
}

.item-list {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 15px;
}

.item {
    padding: 10px;
    border-radius: 4px;
    background: #333;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.item:hover {
    background: #444;
}

.item.selected {
    background: #3498db;
}