.baustelle {
    background-color: #3a3a3a;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.baustelle h2 {
    margin-top: 0;
    color: #ffffff;
}

.baustelle p {
    color: #cccccc;
}

.table-container {
    overflow-x: auto;
    max-width: 100%;
}

.baustelle-table {
    width: 100%;
    border-collapse: collapse;
    color: #ffffff;
}

.baustelle-table th,
.baustelle-table td {
    border: 1px solid #555;
    padding: 8px;
    text-align: left;
}

.baustelle-table thead {
    background-color: #2a2a2a;
}

.baustelle-table tbody tr:nth-child(even) {
    background-color: #444;
}

.baustelle-table tfoot {
    font-weight: bold;
    background-color: #2a2a2a;
}

.time-frames-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
}

.time-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3a3a3a;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.time-frame-actions {
    display: flex;
    gap: 5px;
}

.action-icon, .paste-icon {
    cursor: pointer;
    color: #4CAF50;
    font-size: 14px;
}

.action-icon:hover, .paste-icon:hover {
    color: #45a049;
}

.add-time-frame {
    color: #4CAF50;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    border: 1px dashed #4CAF50;
    border-radius: 4px;
    margin-top: 5px;
}

.add-time-frame:hover {
    background-color: rgba(74, 175, 80, 0.1);
}

.item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-icon-Baustelle {
    cursor: pointer;
    color: #ff4444;
    margin-left: 10px;
}

.delete-icon-Baustelle:hover {
    color: #ff0000;
}

.edit-icon{
    cursor: pointer;
}

.create-bill-icon {
    cursor: pointer;
    margin-left: 10px;
    color: #4CAF50;
}

.create-bill-icon:hover {
    color: #45a049;
}