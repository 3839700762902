.time-frame-editor-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.time-frame-editor {
    background: #2a2a2a;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.time-frame-editor h3 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.time-frame-editor .time-frame {
    margin-bottom: 15px;
    padding: 10px;
    background: #333;
    border-radius: 4px;
}

.time-frame-editor label {
    display: block;
    margin-bottom: 5px;
}

.time-frame-editor input[type="time"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    background: #444;
    color: #fff;
}

.time-frame-editor .remove-btn {
    background: #ff4444;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
}

.time-frame-editor .add-btn {
    background: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
}

.editor-buttons {
    display: flex;
    justify-content: space-between;
}

.editor-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    transition: background-color 0.3s ease;
}

.editor-buttons button:first-child {
    background: #555;
}

.editor-buttons button:first-child:hover {
    background: #444;
}

.editor-buttons button:last-child {
    background-color: #4CAF50;
}

.editor-buttons button:last-child:hover {
    background-color: #45a049;
}

.editor-buttons button:disabled {
    background: #999;
    cursor: not-allowed;
}