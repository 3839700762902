.auth-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #0d1117;  /* Match your dark theme */
}

.auth-content {
    width: 100%;
    max-width: 300px;  /* Match your login-container width */
}