.login-container {
    background-color: #161b22;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    width: 300px;
}

.login-container h1{
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input {
    padding: 0.75rem;
    border: 1px solid #30363d;
    border-radius: 6px;
    background-color: #0d1117;
    color: #c9d1d9;
}

input::placeholder {
    color: #8b949e;
}


button {
    padding: 0.75rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}


.login-button {
    background-color: #3f51b5;
    color: white;
}

.login-button:hover {
    background-color: #283593;
}

.submit-button {
    background-color: #3f51b5;
    color: white;
}

.submit-button:hover {
    background-color: #283593;
}

.forgot-password-link {
    display: block;
    text-align: center;
    color: #58a6ff;
    text-decoration: none;
    padding: 0.5rem;
    margin-top: 1rem;
}

.forgot-password-link:hover {
    color: #1f6feb;
    text-decoration: underline;
}

.signup-button {
    background-color: transparent;
    color: #e91e63;
    border: 1px solid #e91e63;
    margin-top: 1rem;
}

.signup-button:hover {
    background-color: rgba(233, 30, 99, 0.3);
}


.back-to-login-button {
    background-color: transparent;
    color: #58a6ff;
    border: 1px solid #58a6ff;
    margin-top: 1rem;
    width: 100%;
}

.back-to-login-button:hover {
    background-color: rgba(88, 166, 255, 0.3);
}

.message {
    color: #c9d1d9;
    text-align: center;
    margin-top: 1rem;
}